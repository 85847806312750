<template>
  <div class="LED">
    <h1 v-if="!standortName">lädt...</h1>
    <span v-if="standortName">
      <img v-if="power" class="power" src="../assets/lampe-an.png" v-on:click="setPower(false)" />
      <img v-if="!power" class="power" src="../assets/lampe-aus.png" v-on:click="setPower(true)" />
      <h1>Uhr {{standortName}}</h1>
      <h2>Helligkeit</h2>
      <input v-model="brightness" v-on:input="setBrightness()" type="range" min="1" max="100" step="0.1" />
      <h2>Farbe</h2>
      <div v-for="(color, idx) in colors" :key="idx" v-on:click="setColor(idx)" :class="{ active: colorIdx === idx}" class="colorButton" :style="getColorStyle(idx)"><div class="time">{{formatTime(new Date())}}</div></div>
      <h2>Timer</h2>
      <div id="timer" v-on:click="setTimer(null)">⏲ {{timer ? formatTime(new Date(timer * 1000)) : '---'}}</div>
      <input type="hidden" name="timer" value="" />
      <div class="timerButton" v-on:click="setTimer(60)" style="margin-left: 0;">⏲ 0:01</div>
      <div class="timerButton" v-on:click="setTimer(300)">⏲ 0:05</div>
      <div class="timerButton" v-on:click="setTimer(600)">⏲ 0:10</div>
      <div class="timerButton" v-on:click="setTimer(1800)" style="margin-right: 0;">⏲ 0:30</div>
      <div class="timerButton" v-on:click="setTimer(3600)" style="margin-left: 0;">⏲ 1:00</div>
      <div class="timerButton" v-on:click="setTimer(7200)">⏲ 2:00</div>
      <div class="timerButton" v-on:click="setTimer(10800)">⏲ 3:00</div>
      <div class="timerButton" v-on:click="setTimer(18000)" style="margin-right: 0;">⏲ 5:00</div>
    </span>
  </div>
</template>

<script>
import axios from 'axios'
import Konstanten from '../scripts/Konstanten'

export default {
  name: 'ClockView',
  mixins: [Konstanten],
  data () {
    return {
      ledId: null,
      standortName: null,
      power: null,
      colorIdx: null,
      brightness: null,
      timer: null,
      colors: [
        { colorL: '#ffffff', colorR: '#ffffff', color: '#000000' },
        { colorL: '#000000', colorR: '#000000', color: '#ffffff' },
        { colorL: '#000000', colorR: '#000000', color: '#ff0000' },
        { colorL: '#000000', colorR: '#000000', color: '#0000ff' },
        { colorL: '#000000', colorR: '#000000', color: '#00ff00' },
        { colorL: '#000000', colorR: '#000000', color: '#ff8800' },
        { colorL: '#000000', colorR: '#000000', color: '#ff2200' },
        { colorL: '#000000', colorR: '#000000', color: '#ff0022' },
        { colorL: '#0000ff', colorR: '#ff0000', color: '#000000' },
        { colorL: '#ff0000', colorR: '#00ff00', color: '#000000' },
        { colorL: '#ff2200', colorR: '#ff0022', color: '#000000' },
        { colorL: '#00ff00', colorR: '#0000ff', color: '#000000' },
        { colorL: '#0000ff', colorR: '#ff0000', color: '#ffffff' },
        { colorL: '#ff0000', colorR: '#00ff00', color: '#ffffff' },
        { colorL: '#ff2200', colorR: '#ff0022', color: '#ffffff' },
        { colorL: '#00ff00', colorR: '#0000ff', color: '#ffffff' }
      ]
    }
  },

  methods: {
    getColorStyle: function (colIdx) {
      const color = 'color: ' + this.translateColor(this.colors[colIdx].color) + ';'
      const background = 'background: linear-gradient(to right, ' + this.translateColor(this.colors[colIdx].colorL) + ', ' + this.translateColor(this.colors[colIdx].colorR) + ');'
      return color + background
    },

    translateColor: function (color) {
      switch (color) {
        case '#ff8800':
          return '#ffff00'
        case '#ff2200':
          return '#ff6600'
        case '#ff0022':
          return '#ff0088'
        case '#000000':
          return '#333333'
        default:
          return color
      }
    },

    setPower: function (power) {
      this.power = power
      axios.post(this.getFunctionsURL(), {
        type: 'clock',
        ledId: this.ledId,
        power: this.power
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setColor: function (color) {
      this.colorIdx = color
      axios.post(this.getFunctionsURL(), {
        type: 'clock',
        ledId: this.ledId,
        color: this.colors[this.colorIdx].color,
        colorL: this.colors[this.colorIdx].colorL,
        colorR: this.colors[this.colorIdx].colorR
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setBrightness: function () {
      axios.post(this.getFunctionsURL(), {
        type: 'clock',
        ledId: this.ledId,
        brightness: parseInt(this.brightness * this.brightness / 10000.0 * 254.0) + 1
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    setTimer: function (timer) {
      this.timer = timer !== null ? timer + parseInt(new Date().getTime() / 1000) : null
      axios.post(this.getFunctionsURL(), {
        type: 'clock',
        ledId: this.ledId,
        timer: this.timer
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data) console.log(response.data)
      })
    },

    formatTime: function (time) {
      return time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()
    },

    getData: function () {
      axios.post(this.getClockURL(), {
        id: this.ledId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.standortName = response.data.name
        this.power = response.data.power
        const color = response.data.color
        const colorL = response.data.colorLeft
        const colorR = response.data.colorRight
        this.brightness = Math.sqrt((response.data.brightness - 1) / 254.0 * 10000.0)
        this.timer = response.data.timer
        this.colors.forEach((element, idx) => {
          if (color === element.color && colorL === element.colorL && colorR === element.colorR) {
            this.colorIdx = idx
          }
        })
      })
    }
  },

  mounted: function () {
    this.ledId = this.$route.params.standortId
    localStorage.standortType = 'clock'
    localStorage.standortId = this.ledId
    this.getData()
    setInterval(this.getData, 60 * 1000)
  }
}
</script>

<style scoped>

h1 {
  margin: 0;
  font-size: 2.25em;
  color: var(--darkBlue);
  text-decoration: underline;
}

h2 {
  margin: 0.5em 0 0 0;
  font-size: 1.5em;
  color: var(--lightBlue);
}

select, input[type=button] {
  width: calc(98vw);
  padding: 5px;
  border: 3px solid var(--darkBlue);
  background: var(--lightBlue);
  font-size: 1.2em;
  font-weight: bold;
  color: var(--darkBlue);
  text-align: center;
}

input[type=range] {
  height: 2em;
  width: calc(98vw - 6px);
  border: 3px solid var(--darkBlue);
  margin: 0;
  background: var(--lightBlue);
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}
input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 2em;
  background: var(--darkBlue);
  border-radius: 0;
  border: 0
}
input::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 2em;
  background: var(--darkBlue);
  border-radius: 0;
  border: 0
}
input::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 2em;
  background: var(--darkBlue);
  border-radius: 0;
  border: 0
}

.power {
  float: right;
  height: 4em;
  width: 6em;
  background: var(--lightBlue);
  object-fit: contain;
}

.colorButton {
  width: calc(98vw / 4 - 8px);
  border: 4px solid var(--white);
  height: 3em;
  display: inline-block;
  text-align: center;
}
.colorButton .time {
  font-size: 2em;
  font-weight: bold;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.colorButton.active {
  border-color: var(--darkBlue);
}

#timer {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.timerButton {
  width: calc((98vw - 24px) / 4 - 8px);
  border: 4px solid var(--darkBlue);
  font-size: 1.25em;
  text-align: center;
  display: inline-block;
  margin: 4px;
  cursor: pointer;
}

</style>
