<template>
  <div class="home">
    <main>
      <router-view :key="$route.path"></router-view>
    </main>
    <nav>
      <router-link v-for="leiste in leisten" :key="leiste.type+leiste.id" :to="'/'+leiste.type+'/'+leiste.id" :style="menuWidth(leiste.id, leiste.type)" replace><div v-html="leiste.name" /></router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      leisten: [
        {
          id: 1,
          type: 'strip',
          name: 'Fern&shy;seher'
        }, {
          id: 2,
          type: 'strip',
          name: 'Küche'
        }, {
          id: 1,
          type: 'clock',
          name: 'Uhr'
        }, {
          id: 4,
          type: 'strip',
          name: 'Bett'
        }, {
          id: 3,
          type: 'strip',
          name: 'Pots&shy;dam'
        }
      ]
    }
  },

  methods: {
    menuWidth: function (id, type) {
      let style = ''
      let margin = 6
      if (this.leisten[0].id === id && this.leisten[0].type === type) {
        style += 'margin-left: 0;'
        margin -= 3
      }
      if (this.leisten[this.leisten.length - 1].id === id && this.leisten[this.leisten.length - 1].type === type) {
        style += 'margin-right: 0;'
        margin -= 3
      }
      return style + 'width: calc(100vw / ' + this.leisten.length + ' - ' + margin + 'px);'
    }
  }
}
</script>

<style scoped>
nav {
  width: 100vw;
  height: 6vh;
  overflow: hidden;
}

nav a {
  float: left;
  height: 100%;
  margin: 0 3px;
  text-align: center;
  background: var(--darkBlue);
  color: var(--white);
  text-decoration: none;
  font-weight: bold;
  font-size: 3vh;
  line-height: 1;
}

nav a div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

nav a.router-link-active {
  text-decoration: underline;
  color: var(--darkBlue);
  background: var(--white);
}
</style>
